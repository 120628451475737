import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import { Sector } from '../../models';
import { Dropdown } from 'semantic-ui-react';

const SectorSelector = ({ sectors, single, name, onChange, placeholder }) => {
    const { i18n } = useTranslation();

    const tree    = Sector.buildTree(sectors || [], i18n.language);
    const options = [];

    const addSector = (sector, depth) => {
        options.push({
            key: depth + sector.id,
            text: Utils.localized_or_fallback(sector.name, i18n.language),
            value: sector.id
        });
        sector.children.map(child => addSector(child, depth + 1));
    }

    (tree || []).filter(s => !s.parent_id).forEach(sector => addSector(sector, 0));

    return <Dropdown
        name={ name }
        options={ options }
        onChange={ onChange }
        placeholder={ placeholder }
        clearable
        search
        selection
    />;
}

export default SectorSelector;
