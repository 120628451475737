import BaseModel from './BaseModel';

/** Class representing a salepoint. */
class Salepoint extends BaseModel {

    static POS_LAYOUT_TICKETS  = "tickets";
    static POS_LAYOUT_ARTICLES = "articles";

    constructor(properties) {
        super(properties);

        this.places_ids     = this.places_ids || [];
        this.sell_pass      = !!this.sell_pass;
        this.delivery       = this.delivery || {};
        this.delivery.types = this.delivery.types || [];
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = super.prepareForUpdate();

        if (prepared.pos_layout === Salepoint.POS_LAYOUT_TICKETS)
            prepared.sell_pass = false;

        return prepared;
    }
}

export default Salepoint;
