import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import { Link } from "react-router-dom";
import { PageTitle, ApplicationDetails } from '../../../components';

import { Utils } from '../../../services/';
import { loadUser } from '../../../redux';

import user_avatar from '../../../assets/images/user_avatar.jpg';

const UserShow = props => {
    const { t, i18n } = useTranslation();
    const dispatch    = useDispatch();

    const { user }     = useSelector(state => state.users);
    const current_user = useSelector(state => state.auth.user);

    useEffect(() => {
        const { userId } = props.match.params;
        dispatch(loadUser(userId));
    }, []);

    const current_lng         = (i18n.language) ? i18n.language.substr(0, 2) : null;

    if (!current_user || !user)
        return null;

    const title = current_user.id === user.id ?
        <span>{t('users.my_profile')}</span>
        :
        <span>
            {t('users.user')}
            <small> | { user.firstname } { user.lastname }</small>
        </span>

    const actions =
    <div>
        <ButtonGroup>
                <Button tag={Link} to="/users" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            {user &&
                <Button tag={Link} to={ `/users/edit/${ user.id }` } color="info" size="sm">
                    <i className="fa fa-edit"></i>&nbsp;{t('common.edit')}
                </Button>
            }
        </ButtonGroup>
    </div>

    return (
        <div className="UserShow">
            <PageTitle icon="users" title={ title } actions={ actions } />
            {user &&
                <Row>
                    <Col xs={12} lg={3}>
                        <div style={{ maxWidth: '250px', margin: '0 auto 20px auto' }}>
                            <Card>
                                <CardImg top src={user.avatar || user_avatar} alt="Card image cap" />
                            </Card>
                        </div>
                    </Col>
                    <Col xs={12} lg={9}>
                        <Row>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-user-circle"></i>&nbsp;
                                            {t('users.profile')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t('users.firstname')}</b>: { user.firstname }</div>
                                            <div><b>{t('users.lastname')}</b>: { user.lastname }</div>
                                            <div><b>{t('users.role')}</b>: { Utils.roles(user.role).label }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-envelope"></i>&nbsp;
                                            {t('users.contact')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t('users.email')}</b>: { user.email }</div>
                                            <div><b>{t('users.fix_phone')}</b> : { user.fix_phone }</div>
                                            <div><b>{t('users.mobile_phone')}</b> : { user.mobile_phone }</div>
                                            <div><b>{t('users.pro_phone')}</b> : { user.pro_phone }</div>
                                            <div><b>{t('users.fax')}</b> : { user.fax }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-address-card"></i>&nbsp;
                                            {t('users.address')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div>{ user.address }</div>
                                            <div>{ user.zip } {user.city }</div>
                                            <div>{ user.country }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-th-list"></i>&nbsp;
                                            {t('users.other_informations')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t('users.birthday')}</b>: { user.birthday }</div>
                                            <div><b>{t('users.avs')}</b>: { user.avs }</div>
                                            <div><b>{t('users.bank')}</b>: { user.bank }</div>
                                            <div><b>{t('users.bic')}</b>: { user.bic }</div>
                                            <div><b>{t('users.iban')}</b>: { user.iban }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        { current_user?.tkt_instance?.hasModule(Utils.MODULE_TEAM) &&
                            <Row>
                                <Col sm={12} lg={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><i className="fa fa-id-badge"></i>&nbsp;
                                            {t("users.activities")}
                                            </CardTitle>
                                            <CardText tag="div">
                                                    { user.activities && user.activities.map(activity => <div className="badge badge-light mr-2"> {activity.name[current_lng]}</div>) }
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} lg={6}>
                                    { user?.applications?.length > 0 &&
                                        <Card>
                                            <CardBody>
                                                <CardTitle><i className="fa fa-id-badge"></i>&nbsp;{t("users.application")}</CardTitle>
                                                <CardText tag="div">
                                                    { user?.applications?.map(application => (
                                                        <CardText key={ application.id } tag="div">
                                                            <ApplicationDetails application={ application } />
                                                        </CardText>
                                                    ))}
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    }
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-ticket"></i>&nbsp;
                                            {t('tktInstances.tktInstance')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            { user.tkt_instance &&
                                                user.tkt_instance.name
                                            }
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </div>
    );
}

export default UserShow;
